import { useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import useLogout from "../utils/useLogout";

const LogoutTimer = () => {
  const logoutTimer = useRef(null);
  const handleLogoutFunction = useLogout();

  const logout = async () => {
    toast.error("Session expired!");
    await handleLogoutFunction();
  };

  const resetTimer = () => {
    clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(logout, 1800000);
  };

  const handleUserActivity = () => {
    console.log("User activity detected.");
    resetTimer();
  };

  useEffect(() => {
    const userToken = sessionStorage.getItem("userToken");
    console.log("User Token:", userToken);

    if (userToken) {
      resetTimer();
      const events = [
        "mousedown",
        "keydown",
        "mousemove",
        "scroll",
        "touchstart",
      ];

      // Attach event listeners
      const attachEventListeners = () => {
        events.forEach((event) => {
          window.addEventListener(event, handleUserActivity);
        });
      };

      attachEventListeners();

      // Cleanup function
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, handleUserActivity);
        });
        clearTimeout(logoutTimer.current); // Clear the timer
      };
    } else {
      console.log("User token is not available");
    }
  }, [sessionStorage.getItem("userToken")]); // Dependency array should trigger on userToken change

  return null;
};

export default LogoutTimer;
