import React, { useEffect, useRef, useState } from "react";
import {
  AccountCircleOutlined,
  ArrowBack,
  AttachFile,
  KeyboardVoice,
  PhotoCamera,
  Search,
  Send,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Avatar,
  Box,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../community/MasterMindCommunity";
import customAxios from "../../utils/customAxios";
import { ApiConfig, socketUrl } from "../../services/ApiConfig";
import { useLocation, useNavigate, useParams } from "react-router";
import { Container } from "@mui/system";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import Webcam from "react-webcam";
import ScheduleCallPopUp from "../../components/scheduleAction/ScheduleCallPopUp";

export default function AdvisorChat() {
  const scrollToBottom = useRef(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [messagesAdded, setMessagesAdded] = useState(false);
  console.log("🚀 ~ AdvisorChat ~ messagesAdded:", messagesAdded);
  const [currentPage, setCurrentPage] = useState(1);
  console.log("🚀 ~ AdvisorChat ~ currentPage:", currentPage);
  const [totalPages, setTotalPages] = useState(1);
  console.log("🚀 ~ AdvisorChat ~ totalPages:", totalPages);
  const [isFetching, setIsFetching] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isConnected, setIsConnected] = useState(false);
  const [messageList, setMessageList] = useState([]);
  console.log("🚀 ~ AdvisorChat ~ messageList:", messageList);
  const [roomId, setRoomId] = useState("");
  const [message, setMessage] = useState("");
  const chatScreen = useRef();
  const [isListening, setIsListening] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const socketRef = useRef(null);
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const socketEvent = {
    initiateChat: "initiateUserChat",
    joinChatRoom: "joinChatRoom",
    sendMessage: "userSendMessage",
    userMessage: "getUserMessage",
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        secure: true,
        reconnect: true,
        transports: ["websocket"],
      });
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);
  const userId = sessionStorage.getItem("userId");
  const advisorId = location?.state?.advId;
  const advName = location?.state?.advName;
  const image_url = location?.state?.image_url;

  useEffect(() => {
    if (isConnected) {
      handleInitialSocketConnect();
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected) {
      receiveMessageHandler();
      userMessageHandler();
    }
  }, [isConnected]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on("connect", () => {
        setIsConnected(true);
        setInitialLoading(false);
      });
      return () => {
        socketRef?.current?.off("connect");
        socketRef?.current?.off(socketEvent.initiateChat);
      };
    }
  }, [roomId]);

  const handleChatData = () => {
    if (socketRef.current) {
      socketRef.current.off("userChatInitiated");
      socketRef.current.on("userChatInitiated", (data) => {
        console.log(data, "data");

        // Only set messages if it's the initial load (when prevMessageList is empty)
        setMessageList((prevMessageList) => {
          if (prevMessageList.length === 0) {
            return data?.messages || [];
          }
          return prevMessageList; // Avoid overwriting existing messages
        });

        setRoomId(data?.chatRoomId);
        setCurrentPage(data.currentPage); // Set the current page received
        setTotalPages(data.totalPages); // Set the total pages from the response
      });
    }
  };
  const handleInitialSocketConnect = () => {
    if (socketRef.current) {
      const roomData = {
        userId: userId,
        roomId: userId,
        //   userOnlineFlag: true,
        page: currentPage,
        limit: 50,
      };
      socketRef.current.emit(socketEvent.initiateChat, roomData);
      handleChatData();
    }
  };

  // Track scroll event
  const handleScroll = () => {
    console.log("🚀 ~ handleScroll is being call correctly");
    const chatContainer = chatScreen.current;
    if (
      chatContainer.scrollTop === 0 &&
      !isFetching &&
      currentPage < totalPages
    ) {
      setIsFetching(true);
      loadMoreMessages();
    }
  };

  const loadMoreMessages = () => {
    if (socketRef.current && currentPage < totalPages) {
      const roomData = {
        userId: sessionStorage.getItem("userId"),
        roomId: roomId,
        page: currentPage + 1,
        limit: 50,
      };

      socketRef.current.emit(socketEvent.initiateChat, roomData);
      setIsLoadingMessages(true);
      socketRef.current.on("userChatInitiated", (data) => {
        const chatContainer = chatScreen.current;
        const scrollTopBefore = chatContainer.scrollTop;
        const scrollHeightBefore = chatContainer.scrollHeight;
        setMessageList((prevMessageList) => {
          const newMessages = data?.messages || [];

          // Prepend the new (older) messages to the existing list of messages
          return [...newMessages, ...prevMessageList];
        });
        setMessagesAdded({ scrollTopBefore, scrollHeightBefore });
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
        setIsFetching(false);
        setIsLoadingMessages(false);
      });
    }
  };

  useEffect(() => {
    const chatContainer = chatScreen?.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageList, currentPage, totalPages]);

  useEffect(() => {
    const chatContainer = chatScreen?.current;
    if (chatContainer && scrollToBottom) {
      const lastMessage = chatContainer.lastChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({ block: "end" });
      }
    }
    !scrollToBottom.current && scrollToLastMessage();
    scrollToBottom.current = false;
  }, [messageList]);

  const scrollToLastMessage = () => {
    if (messagesAdded) {
      console.log("🚀 ~ scrollToLastMessage ~ scrollToLastMessage is called :");
      const chatContainer = chatScreen.current;
      const scrollHeightAfter = chatContainer.scrollHeight;
      chatContainer.scrollTop =
        scrollHeightAfter -
        messagesAdded.scrollHeightBefore +
        messagesAdded.scrollTopBefore;
      setMessagesAdded(false);
    }
  };
  const receiveMessageHandler = () => {
    console.log("receive");
    if (socketRef.current) {
      setIsLoading(true);
      socketRef.current.on("receiveUserMessage", (data) => {
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });
      socketRef.current.on(socketEvent.userMessage, (data) => {
        console.log("Received userMessage:", data);
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });

      socketRef.current.on("receiveUserMessage", () => setIsLoading(false));
      socketRef.current.on(socketEvent.userMessage, () => setIsLoading(false));
    }
  };
  const sendMessageHandler = async () => {
    setShowEmojiPicker(false);
    try {
      if (socketRef.current) {
        const trimmedMessage = message?.trim();
        if (!trimmedMessage) {
          console.log("Cannot send an empty message");
          return;
        }

        console.log("Attempting to send userMessage:", {
          from: userId,
          room_id: userId,
          advisor_id: advisorId,
          message: trimmedMessage,
        });

        socketRef.current.emit(socketEvent.sendMessage, {
          from: userId,
          room_id: userId,
          to: advisorId,
          message: trimmedMessage,
        });

        console.log("userMessage event emitted");

        setMessage("");
      } else {
        console.log("Socket not initialized");
      }
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };
  const userMessageHandler = async () => {
    try {
      if (socketRef.current) {
        socketRef.current.emit(socketEvent.userMessage, {
          from: userId,
          room_id: userId,
          // advisor_id: advisorId,
          message: message?.trim(),
        });
        setMessage("");
      }
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };
  const handleFileSelect = (files) => {
    if (files.length > 0) {
      const selectedFile = files[0];
    }
  };
  const disconnectSocket = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.disConnectSocket,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEmojiClick = (event, emojiObject) => {
    console.log(event, emojiObject, "aasasasas");
    if (event?.emoji) {
      setMessage((prevMessage) => prevMessage + event.emoji);
    }
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };
  const handleVoiceInput = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      console.log("Voice recognition started.");
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Transcript:", transcript);
      setMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Voice recognition ended.");
      setIsListening(false);
    };

    recognition.start();
  };
  return (
    <Box style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}>
      <Container maxWidth="lg">
        <div
          className="masterMindCommunityContainer"
          style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}
        >
          <div
            className="header"
            style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              />
            </IconButton>
            <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
              Advisor Chat
            </h2>
          </div>

          <div className="chatContainer" style={{ justifyContent: "center" }}>
            {/* <Box
              className="chatList"
              sx={{
                background: currentTheme == "DARK" ? "#000" : "#fff",
                height: "70vh",
                display: isMediumScreen ? "none" : "block",
              }}
            >
              <div className="header"></div>
            </Box> */}
            <div className="chatScreen" style={{ width: "75%" }}>
              {initialLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    position: "absolute",
                    top: "10%",
                    left: "40%",
                    width: "300px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div
                    className="header"
                    style={{
                      background: currentTheme == "DARK" ? "#000" : "#fff",
                    }}
                  >
                    <Avatar
                      sx={{
                        height: "50px",
                        width: "50px",
                      }}
                      src={image_url}
                    />
                    <p
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {advName}
                    </p>
                  </div>
                  <Box>
                    <div
                      style={{
                        // height: "50vh",
                        position: "relative",
                      }}
                    >
                      <div className="chatDetails" ref={chatScreen}>
                        {isLoadingMessages && (
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: "999",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            top: "40%",
                            textAlign: "center",
                          }}
                        >
                          {messageList.length <= 0
                            ? "There are currently no messages. Feel free to start the conversation!"
                            : ""}
                        </div>
                        {messageList?.map((data, i) => {
                          const isSentByUser = data.from === userId;
                          return (
                            <div
                              key={i}
                              className={`chatBubble ${
                                isSentByUser
                                  ? "chatBubbleSelf"
                                  : "chatBubbleOther"
                              }`}
                            >
                              <div>
                                <span
                                  style={{
                                    color: "#c25208",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {data?.user_unique_id}
                                </span>
                              </div>
                              <div style={{ wordBreak: "keep-all" }}>
                                {data?.message}
                              </div>

                              <span className="bubbleChatDate">
                                {data?.createdAt
                                  ? dayjs(data?.createdAt)?.format("lll")
                                  : "NA"}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Box>
                  <div
                    className="chatKeyboard"
                    style={{
                      background: currentTheme == "DARK" ? "#000" : "#fff",
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e.target.files)}
                    />

                    <div
                      className="keypad"
                      style={{
                        background: currentTheme == "DARK" ? "#000" : "#fff",
                      }}
                    >
                      <input
                        placeholder="Type a message..."
                        value={message}
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                        onChange={(e) => {
                          setMessage(e?.target?.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && message.trim()) {
                            sendMessageHandler();
                          }
                        }}
                      />
                      <KeyboardVoice
                        onClick={handleVoiceInput}
                        className={isListening ? "listening" : ""}
                        style={{
                          margin: "0 25px 0 0",
                          color: currentTheme == "DARK" ? "#fff" : "#909090",
                          cursor: "pointer",
                        }}
                      />
                      {/* <VoiceInputButton onVoiceInput={handleVoiceInput} /> */}
                      <SentimentSatisfiedAlt
                        style={{
                          color: currentTheme === "DARK" ? "#fff" : "#909090",
                          margin: "0 29px 0 0",
                          cursor: "pointer",
                        }}
                        onClick={toggleEmojiPicker}
                      />
                      <div>
                        <IconButton
                          onClick={sendMessageHandler}
                          disabled={!message.trim()}
                        >
                          <Send />
                        </IconButton>
                      </div>
                    </div>
                    {showEmojiPicker && (
                      <EmojiPicker
                        style={{
                          position: " absolute",
                          bottom: "83px",
                          left: "450px",
                        }}
                        onEmojiClick={handleEmojiClick}
                        disableSearchBar
                        disableSkinTonePicker
                        groupVisibility={{ recently_used: false }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
}
