import { useNavigate } from "react-router-dom";
import { ApiConfig } from "../services/ApiConfig";
import customAxios from "./customAxios";

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogoutFunction = async () => {
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.userLogout,
        data: {
          session_id: JSON.parse(sessionStorage.getItem("user"))?.session_id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data?.statusCode === 200) {
        sessionStorage.clear();
        navigate("/login");
      } else {
        console.log("Error during logout");
      }
    } catch (error) {
      console.log(error, "Error in logout function");
    }
  };

  return handleLogoutFunction;
};

export default useLogout;
